// import { useState, useEffect } from "react";

function Cities(props) {

    return (
        <div className="cities">
            <div className="cities__wrapper">

                <h2
                    style={{ 
                        borderColor: props.personStyle.color,
                        color: props.personStyle.color 
                    }} 
                >
                    Visit...
                </h2>

                <div className="cities__links">
                        
                    <a
                        href="https://visitzagreb.city"
                        // target="_blank"
                        rel="noreferrer"
                        className={`cities__link ${window.city_code === 'zagreb' && 'active'}`}
                        style={{ 
                            borderColor: props.personStyle.color,
                            color: props.personStyle.color 
                        }} 
                    >
                        Zagreb
                    </a>

                    <a
                        href="https://visitrijeka.city"
                        // target="_blank"
                        rel="noreferrer"
                        className={`cities__link ${window.city_code === 'rijeka' && 'active'}`}
                        style={{ 
                            borderColor: props.personStyle.color,
                            color: props.personStyle.color 
                        }} 
                    >
                        Rijeka
                    </a>

                    <a
                        href="https://visitsplit.city"
                        // target="_blank"
                        rel="noreferrer"
                        className={`cities__link ${window.city_code === 'split' && 'active'}`}
                        style={{ 
                            borderColor: props.personStyle.color,
                            color: props.personStyle.color 
                        }} 
                    >
                        Split
                    </a>

                </div>

            </div>
        </div>
    );
}

export default Cities;
