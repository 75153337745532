import { useState, useEffect } from "react";
import { ReactSVG } from 'react-svg'


const slogans = [
    "people call it",
    "people say it's",
    "experienced as",
    "described as",
]

function Info(props) {

    const [sloganIdx, setSloganIdx] = useState(0);

    
    useEffect(() => {
        setSloganIdx( Math.floor(Math.random()*slogans.length) );

        setTimeout(() => {
            const targetNode = document.querySelector(".word--active");
            if( targetNode !== null ){
                targetNode.style.transform = `translateX(-${targetNode.offsetWidth/2})`;
            }
            // const sloganNode = document.querySelector('.slogan');
            // sloganNode.style.width = `${targetNode.offsetWidth}px`;
        },500);
    }, [props.index]);

    return (
        <div className="info">

            <ReactSVG 
                src={`logo_desktop_${window.city_code}.svg`}
                className="logo"
                style={{
                    fill: props.personStyle.color,
                    stroke: props.personStyle.color
                }}
            />

            { props.index !== null && 
                <span 
                    className="slogan"
                    style={{color: props.personStyle.color}}
                >
                    {slogans[sloganIdx]}...
                </span>
            }

        </div>
    );
}

export default Info;
