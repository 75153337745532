import { useEffect } from "react";

function Words(props) {


    useEffect(() => {

        let elemOriginal = document.querySelector('.word__original');
        if( elemOriginal !== null && elemOriginal !== undefined ){
            elemOriginal.classList.remove('fx');
            setTimeout(() => {
                elemOriginal.classList.add('fx');
            }, 50);
        }

        let elemFooter = document.querySelector('.word__footer');
        if( elemFooter !== null && elemFooter !== undefined ){
            elemFooter.classList.remove('fx');
            setTimeout(() => {
                elemFooter.classList.add('fx');
            }, 50);
        }
        
    // eslint-disable-next-line
    }, [props.index]);


    return (
        <div className="words">

            <div className="words__wrapper">

                <div 
                    className={`word word--active`}
                >
                    <span 
                        onClick={() => {
                            if( props.content === 'listing' 
                                || props.content === 'videos'
                                || props.content === 'cities'
                            ){
                                props.updateContent('images', {scrollContainer: '.persons__wrapper'});   
                            }
                            if( props.content === 'images' ){
                                props.updateContent('listing', {scrollContainer: '.listing__wrapper'});
                            }
                        }}
                        className={`word__original`}
                        style={{ 
                            color: props.personStyle.color,
                            borderColor: props.personStyle.color
                        }}
                    >
                        {props.json[props.index].word}
                    </span>

                    <div 
                        className="word__footer"
                        style={{ 
                            color: props.personStyle.color,
                        }}
                    >
                        {props.json[props.index].translation.split(',').map((transl, translIdx) => 
                            <span 
                                key={`translation_${translIdx}`}
                                className="word__translation"
                            >
                                    {transl}
                            </span>
                        )}
                    </div>
                </div>

            </div>

        </div>
    );
}

export default Words;
