// import {} from 'react';
// import { useState, Fragment } from "react";
import { setColor } from '../Helpers/Functions';
import { IoSyncSharp } from "react-icons/io5";

function Listing(props) {

    const rotateRefresh = () => {
        let elemReload = document.querySelector('[data-cta-reload]');
        if( elemReload !== null && elemReload !== undefined ){
            elemReload.classList.add('fx');
            setTimeout(() => {
                elemReload.classList.remove('fx');
            },1000);
        }
    }

    const wordsSorted = [...props.json].sort((a, b) => a.word.localeCompare(b.word));

    return (
        <div className="listing"
            style={{
                background: setColor(props.personStyle.backgroundColor, -10)
            }}
        >

            <div 
                className="listing__wrapper"
                style={{ color: props.personStyle.color }}
            >
                { wordsSorted
                    .map( (word, wordIdx) => {
                    const originalIdx = props.json.findIndex(x => x._id === word._id);
                    return (
                        <div 
                            key={`word_${wordIdx}`}
                            data-item-idx={originalIdx}
                            className="listing__item"
                            onClick={() => {props.setAndGotoIndex(originalIdx)}} 
                        >
                            {(originalIdx === props.index)
                            
                            ? <span style={{ borderBottomColor: props.personStyle.color }}>
                                {word.word}
                            </span>

                            : <span>
                                {word.word}
                            </span>
                        }
                        </div>
                    )}
                )}

            </div>

            <button
                onClick={() => {
                    rotateRefresh();
                    props.setAndGotoRandomIndex();
                }}
                className="listing__btn listing__btn--shuffle"
                style={{ 
                    background: props.personStyle.backgroundColor,
                    borderColor: props.personStyle.color 
                }} 
            >
                <IoSyncSharp 
                    data-cta-reload
                    style={{ 
                        fill: props.personStyle.color,
                        stroke: props.personStyle.color
                    }}
                />
            </button>

        </div>
    );
}

export default Listing;