import { Fragment, useEffect } from 'react';
import { /*IoPlaySharp, IoPauseSharp, IoPeopleSharp, */IoSyncSharp } from "react-icons/io5";

function Actions(props) {

    useEffect(() => {
        rotateRefresh();
    }, [props.index]);

    const rotateRefresh = () => {
        let elemReload = document.querySelector('[data-cta-reload]');
        if( elemReload !== null && elemReload !== undefined ){
            elemReload.classList.add('fx');
            setTimeout(() => {
                elemReload.classList.remove('fx');
            },1000);
        }
    }

    return (
        <div className="actions">

            
            {/*** single ***/}

            { props.mode === 'single' && 
                <Fragment>

                    {/*
                    <button
                        onClick={() => props.updateMode()}
                        style={{ 
                            background: props.personStyle.backgroundColor,
                            borderColor: props.personStyle.color 
                        }} 
                    >
                        <IoPeopleSharp 
                            style={{ fill: props.personStyle.color }}
                        />
                    </button>
                    */}

                    <button
                        onClick={() => {
                            rotateRefresh();
                            props.setAndGotoRandomIndex();
                        }}
                        style={{ 
                            background: props.personStyle.backgroundColor,
                            borderColor: props.personStyle.color 
                        }} 
                    >
                        <IoSyncSharp 
                            data-cta-reload
                            style={{ 
                                fill: props.personStyle.color,
                                stroke: props.personStyle.color
                            }}
                        />
                    </button>

                    {/*
                    <button
                        onClick={() => props.togglePlaying()}
                        style={{ 
                            background: props.personStyle.backgroundColor,
                            borderColor: props.personStyle.color 
                        }} 
                    >
                        { props.playing === true && 
                            <IoPauseSharp
                                style={{ fill: props.personStyle.color }} 
                            />
                        }

                        { props.playing === false && 
                            <IoPlaySharp 
                                style={{ fill: props.personStyle.color }}
                            />
                        }

                    </button>
                    */}

                </Fragment>
            }



            {/*** grid ***/}
            { props.mode === 'grid' && 
                <Fragment>
                    {/*
                    <button
                        onClick={() => props.togglePlaying()}
                        style={{ 
                            background: props.personStyle.backgroundColor,
                            borderColor: props.personStyle.color 
                        }} 
                    >
                        { props.playing === true && 
                            <IoPauseSharp
                                style={{ fill: props.personStyle.color }} 
                            />
                        }

                        { props.playing === false && 
                            <IoPlaySharp 
                                style={{ fill: props.personStyle.color }}
                            />
                        }

                    </button>
                    */}
                </Fragment>
            }

        </div>
    );
}

export default Actions;