import { useEffect } from 'react';
import { cdnUrl, cdnParams } from '../Helpers/Config';
import { setColor } from '../Helpers/Functions';
import Footer from './Footer';

function Persons(props) {


    useEffect(() => {
        // 
    }, [props.index])


    useEffect(() => {
        scrollToPerson(props.index);

        if( props.playing === true ){
            let elemProgress = document.querySelector('[data-item-progress]');
            if( elemProgress !== null && elemProgress !== undefined ){
                elemProgress.classList.remove('fx');
                setTimeout(() => {
                    elemProgress.classList.add('fx');
                },50);
            }
        }

    }, [props.index, props.item, props.playing]);


    const scrollToPerson = (index) => {
        setTimeout(() => {
            let wrapper = document.querySelector('.persons__wrapper');
            let elemScroll = wrapper.querySelector(`[data-item-idx="${index}"]`);
            if( elemScroll === null || elemScroll === undefined ){
                return false;
            }

            if( wrapper !== undefined 
                && wrapper !== null 
                && elemScroll !== undefined
                && elemScroll !== null
            ){
                elemScroll.scrollIntoView({
                    behavior: 'smooth',
                    inline: 'center',
                    block: 'start'
                });
            }
        },500);
    }

    /*
    console.log(
        props.personStyle.backgroundColor,
        setColor(props.personStyle.backgroundColor, -150)
    );
    */

    return (
        <div 
            className="persons"
            style={{
                background: setColor(props.personStyle.backgroundColor, -10)
            }}
        >
            <div 
                className="persons__wrapper"
            >
                {props.json.map((person, personIdx) => {

                    const cdnParamsFilter = (props.index === personIdx)
                        ? ''
                        : '';
                        //: `&desaturate=-70&colorize=${props.personStyle.backgroundColor.replace('#','')},1`;
                    
                    const cdnParamsWidth = (window.innerWidth > 992 )
                        ? 800
                        : 400;

                    return <div 
                        key={`grid_person_${person.file}`}
                        data-item-idx={personIdx}
                        className={`persons__item ${(props.index === personIdx)?'persons__item--active':''}`}
                        onClick={() => {
                            if(props.index === personIdx){
                                // props.updateMode();
                            }else{
                                props.setAndGotoIndex(personIdx);
                            }
                        }}
                    >
                        <img 
                            src={`${cdnUrl}${person.file}${cdnParams}&width=${cdnParamsWidth}${cdnParamsFilter}`} 
                            alt={person.word} 
                        />
                    </div>
                })}
            </div>

            <Footer 
                personStyle={props.personStyle}
            />

        </div>
    );
}

export default Persons;
