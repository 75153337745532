export const baseUrl = (process.env.NODE_ENV === 'development')
	? 'http://localhost/visitzagreb/api/'
	: 'https://www.borisvidosevic.com/visitcity/';

export const apiUrl = (process.env.NODE_ENV === 'development')
	? 'http://localhost/visitzagreb/api/'
	: 'https://www.borisvidosevic.com/visitcity/';

export const cdnUrl = (process.env.NODE_ENV === 'development') 
    ? `http://localhost/visitzagreb/api/`
    : `https://agewvlkkgp.cloudimg.io/v7/borisvidosevic.com/visitcity/`;

export const cdnParams = `?force_format=jpeg&func=crop`;

export const slideshowTime = 7000;