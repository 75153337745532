import { /*IoMailSharp, */IoLogoInstagram } from "react-icons/io5";

function Footer(props) {


    return (
        <div className={`footer footer--${props.content} `}>

            { props.content === 'tv' && 
                <p style={{ 
                    fontSize: '1.5em',
                    lineHeight: '4em',
                    fontWeight: 'bold',
                    color: props.personStyle.color
                    }}
                >
                    @visitzagreb &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; visitzagreb.city
                </p>
            }

            <p style={{ color: props.personStyle.color }}>
                <strong>Portraits and reels on the streets of {window.city_string} over conversations summed up in a single word.</strong>
            </p>

            <p style={{ color: props.personStyle.color }}>
                Unauthorized use of content is strictly prohibited.
                Copyright Visit {window.city_string} &copy; 2024.
            </p>

            { props.content !== 'tv' && 
                <div className={`footer__social`}>

                    <a
                        href="https://www.instagram.com/visitzagreb"
                        target="_blank"
                        rel="noreferrer"
                        className="footer__btn"
                        style={{ 
                            // background: props.personStyle.backgroundColor,
                            // borderColor: props.personStyle.color 
                        }} 
                    >

                        { props.content !== 'tv' && 
                            <IoLogoInstagram 
                                style={{ 
                                    fill: props.personStyle.color,
                                    stroke: props.personStyle.color
                                }}
                            />
                        }

                    </a>

                    {/*
                    <a 
                        href="mailto:hello@visitzagreb.city"
                        target="_blank"
                        rel="noreferrer"
                        className="footer__btn"
                    >
                        <IoMailSharp 
                            style={{ 
                                fill: props.personStyle.color,
                                stroke: props.personStyle.color
                            }}
                        />
                    </a>
                    */}
                    
                </div>
            }

        </div>
    );
}

export default Footer;
