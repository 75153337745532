// import {} from 'react';
import { useState, Fragment } from "react";
import { setColor } from '../Helpers/Functions';
import { IoMenuSharp } from "react-icons/io5";
import { ReactSVG } from 'react-svg'


function ContentSwitch(props) {

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen( !isOpen );
    }

    const setContent = ( contentToSet, params ) => {
        props.updateContent(contentToSet, params);
        setIsOpen( false );
    }

    return (
        <Fragment>

            <button
                onClick={() => {
                    toggleMenu();
                }}
                className={`content-switch-toggler ${(isOpen)?'content-switch-toggler--active':''}`}
                style={{ 
                    // background: props.personStyle.color,
                    // borderColor: props.personStyle.color 
                }} 
            >
                <IoMenuSharp 
                    style={{ 
                        fill: props.personStyle.color,
                        stroke: props.personStyle.color
                    }}
                />
            </button>

            <div 
                className={`content-switch ${(isOpen)?'content-switch--active':''}`}
                style={{ 
                    background: props.personStyle.backgroundColor,
                    // borderColor: props.personStyle.color 
                }} 
            >

                <div className="content-switch__container">

                    <ReactSVG 
                        onClick={() => toggleMenu()}
                        src={`logo_desktop_${window.city_code}.svg`}
                        className="logo"
                        style={{
                            width: '205px',
                            margin: '0 0 40px 0',
                            fill: props.personStyle.color,
                            stroke: props.personStyle.color
                        }}
                    />

                    <button 
                        onClick={() => setContent('images', {scrollContainer: '.persons__wrapper'})}
                        className={`menu-item ${(props.content === 'images')?'active':''}`}
                        style={(props.content === 'images')
                            ?{color: props.personStyle.color}
                            :{color: setColor(props.personStyle.color, -30)}
                        }
                    >portraits</button>

                    <button 
                        onClick={() => setContent('listing', {scrollContainer: '.listing__wrapper'})}
                        className={`menu-item ${(props.content === 'listing')?'active':''}`}
                        style={(props.content === 'listing')
                            ?{color: props.personStyle.color}
                            :{color: setColor(props.personStyle.color, -30)}
                        }
                    >words</button>

                    <button 
                        onClick={() => setContent('videos')}
                        className={`menu-item ${(props.content === 'videos')?'active':''}`}
                        style={(props.content === 'videos')
                            ?{color: props.personStyle.color}
                            :{color: setColor(props.personStyle.color, -30)}
                        }
                    >reels</button>

                    {/*
                    <button 
                        onClick={() => setContent('cities')}
                        className={`menu-item ${(props.content === 'cities')?'active':''}`}
                        style={(props.content === 'cities')
                            ?{color: props.personStyle.color}
                            :{color: setColor(props.personStyle.color, -30)}
                        }
                    >cities</button>
                    */}

                </div>

            </div>
        </Fragment>
    );
}

export default ContentSwitch;