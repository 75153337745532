import { useEffect } from "react";
// import { ReactSVG } from 'react-svg'

function Cursor(props) {
    
    useEffect(() => {
        window.addEventListener("mousemove", function () {
            document.body.classList.add("canHover");
        });
        window.addEventListener("touchstart", function () {
            let elemC = document.querySelector('.cursor');
            if( elemC !== null ){
                document.querySelector('.cursor').classList.add('hide');
            }
        });
    }, [])


    useEffect(() => {
        let elemC = document.querySelector('.cursor');

        if( elemC === null || elemC === undefined ){
            return false;
        }

        elemC.style.color = props.personStyle.color;
        elemC.style.backgroundColor = props.personStyle.color;
        elemC.style.borderColor = props.personStyle.color;

        document.onmousemove = (e) => {
            let elemC = document.querySelector('.cursor');

            // console.log( [...e.target.classList] );
            const elemClasses = [...e.target.classList];

            // general fx
            if( 
                elemClasses.includes('word__original')
                || elemClasses.includes('persons__item') 
                || elemClasses.includes('listing__item')
                || elemClasses.includes('content-switch-toggler')
                || elemClasses.includes('menu-item')
                || elemClasses.includes('footer__btn')
                || elemClasses.includes('cities__link')
            ){
                elemC.classList.add('active');
            }else{
                elemC.classList.remove('active');
            }

            // blend on switch
            if( 
                elemClasses.includes('word__original')
            ){
                elemC.classList.add('blend');
            }else{
                elemC.classList.remove('blend');
            }

            if ( elemClasses.includes('content-switch-toggler') ){
                elemC.classList.add('menu');
            }else{
                elemC.classList.remove('menu');
            }

            // empty circle on link
            if( 
                elemClasses.includes('listing__item')
                || elemClasses.includes('persons__item') 
                || elemClasses.includes('menu-item')
                || elemClasses.includes('footer__btn')
                || elemClasses.includes('cities__link')
            ){
                elemC.classList.add('circled');
            }else{
                elemC.classList.remove('circled');
            }

            elemC.style.top = e.clientY + -(elemC.clientHeight/2) + 'px';
            elemC.style.left = e.clientX + -(elemC.clientWidth/2) + 'px';
        };

    // eslint-disable-next-line
    }, [props.personStyle]);


    return (
        <div className="cursor" />
    );
}

export default Cursor;
