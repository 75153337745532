import { Fragment, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { apiUrl , slideshowTime} from '../Helpers/Config';

import Info from                '../Components/Info';
import Words from               '../Components/Words';
import Persons from             '../Components/Persons';
import Person from              '../Components/Person';
import Reels from               '../Components/Reels';
import Listing from             '../Components/Listing';
import Cities from              '../Components/Cities';
import Actions from             '../Components/Actions';
import ContentSwitch from       '../Components/ContentSwitch';
import Footer from              '../Components/Footer';
import Cursor from              '../Components/Cursor';



function Main() {


    const appRef = useRef();


    const [json, setJson] = useState([]);
    const [index, setIndex] = useState(null);
    const [person, setPerson] = useState(null);
    const [mode, setMode] = useState('grid');
    const [content, setContent] = useState('images');
    const [jsonReels, setJsonReels] = useState([]);
    const [reel, setReel] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [stateTimeout, setStateTimeout] = useState(null);
    const [keyboardEventsSet, setKeyboardEventsSet] = useState(false);



    useEffect(() => {
        getPersons();
        getReels();

        if( window.location.search.includes('tv=true') ){
            setContent('tv');
        }

        return () => {
            clearTimeout(stateTimeout);
            setStateTimeout(null);
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if( json.length > 0 && keyboardEventsSet === false ){
            setKeyboardEvents();
        }
    // eslint-disable-next-line
    }, [json])

    useEffect(() => {
        if( json.length > 0){
            setPerson(json[index]);

            if( playing === true ){
                setStateTimeout( 
                    setTimeout(() => updateIndex(1), slideshowTime) 
                );
            }else{
                clearTimeout(stateTimeout);
                setStateTimeout(null);
            }

            appRef.current.dataset.idx = index;

            clearTimeout(stateTimeout);
            setStateTimeout(null);
        }
    // eslint-disable-next-line
    }, [json, index]);

    useEffect(() => {
        if( playing === false ){
            clearTimeout(stateTimeout);
            setStateTimeout(null);
        }else{
            setStateTimeout( 
                setTimeout(() => updateIndex(1), slideshowTime) 
            );
        }
    // eslint-disable-next-line
    }, [playing])

    useEffect(() => {
        if( content === 'images' ){
            setPlaying(false);
        }
        if( content === 'videos' ){
            setPlaying(true);
        }
        if( content === 'listing' ){
            setPlaying(false);
        }
        if( content === 'cities' ){
            setPlaying(false);
        }
        if( content === 'tv' ){
            setPlaying(false);
        }
    }, [content]);

    const updateIndex = (delta) => {
        if( playing === true ){
            let newIndex;
            // prev
            if( delta < 1 ){
                newIndex = ( index > 0 ) 
                    ? index - 1 
                    : json.length-1;
            }
            // next
            else{
                newIndex = ( index < json.length-1 ) 
                    ? index + 1 
                    : 0;
            }
            
            // clearTimeout(stateTimeout);
            // setStateTimeout(null);
            setTimeout(() => {
                setIndex(newIndex);
            }, 100);
        }
    }

    const getPersons = () => {
        axios.get(`${apiUrl}get.php?t=img&c=${window.city_code}`)
            .then((response) => response.data)
            .then(data => {
                setJson(data);
                if( data.length > 0){
                    setIndex(0);
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const getReels = () => {
        axios.get(`${apiUrl}get.php?t=reel&c=${window.city_code}`)
            .then((response) => response.data)
            .then(data => {
                setJsonReels(data);
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const setAndGotoIndex = (index) => {
        setIndex(index);
    }

    const setAndGotoRandomIndex = () => {
        const minimum = 0;
        const maximum = json.length-1;
        const randomIdx = Math.floor(Math.random() * maximum) + minimum;

        if( randomIdx === index ){
            setAndGotoRandomIndex();
            return false;
        }

        setPlaying(false);
        setIndex(randomIdx);
    }

    const setKeyboardEvents = () => {
        
        if( keyboardEventsSet === true ){
            return false;
        }

        document.addEventListener('keyup', (e, index) => {
            if( e.key.toLowerCase() === 'l' ){
                const oldIdx = parseInt(appRef.current.dataset.idx);
                const newIdx = (oldIdx === 0 ) 
                    ? json.length - 1
                    : oldIdx - 1;
    
                setIndex(newIdx);
            }
            if( e.key.toLowerCase() === 'r' ){
                const oldIdx = parseInt(appRef.current.dataset.idx);
                const newIdx = (oldIdx < json.length - 1 ) 
                    ? oldIdx + 1 
                    : 0;
                
                setIndex(newIdx);
            }
        })

        setKeyboardEventsSet(true);
    }

    const updateMode = () => {
        if( mode === 'single' ){
            setMode('grid');
        }
        if( mode === 'grid' ){
            setMode('single');
        }
        setPlaying(false);
    }

    const updateContent = ( contentToSet, params = {} ) => {
        setContent( contentToSet );

        if( params.hasOwnProperty('scrollContainer') && params.scrollContainer !== '' ){
            setMode('grid');

            setTimeout(() => {
                let wrapper = document.querySelector(params.scrollContainer);
                let elemScroll = wrapper.querySelector(`[data-item-idx="${index}"]`);
                if( elemScroll === null || elemScroll === undefined ){
                    return false;
                }

                if( wrapper !== undefined 
                    && wrapper !== null 
                    && elemScroll !== undefined
                    && elemScroll !== null
                ){
                    elemScroll.scrollIntoView({
                        behavior: 'smooth',
                        inline: 'center',
                        block: 'start'
                    });
                }
            },500);
            
        }

    }

    const togglePlaying = () => {
        setPlaying(!playing);
    }




    let uiColors = {
        color: '#222', 
        backgroundColor: `#fff`
    };

    if( content === 'images' && person !== null ){
        uiColors = person;
    }
    if( content === 'videos' && reel !== null  ){
        uiColors = reel;
    }
    if( content === 'listing' && person !== null ){
        uiColors = person;
    }
    if( content === 'cities' && person !== null ){
        uiColors = person;
    }
    if( content === 'tv' && person !== null ){
        uiColors = person;
    }


    
    return (
        <div 
            className="main"
            data-content={content}
            ref={appRef}
        >

            <div 
                className="wrapper" 
                style={{ background: uiColors.backgroundColor }}
            >

                { json.length > 0 && 

                    <Fragment>

                        <div className="left">

                            { jsonReels.length > 0 && content !== 'tv' &&
                                <ContentSwitch 
                                    content={content}
                                    updateContent={updateContent}
                                    personStyle={uiColors}
                                />
                            }

                            <Info 
                                index={index}
                                personStyle={uiColors}
                            />

                            <Words 
                                json={json}
                                index={index}
                                content={content}
                                updateContent={updateContent}
                                personStyle={uiColors}
                            />

                            <Footer 
                                personStyle={uiColors}
                                content={content}
                            />

                        </div>

                        <div className="right">

                            { content === 'videos' && 
                                <Reels 
                                    jsonReels={jsonReels}
                                    personStyle={uiColors}
                                    setReel={setReel}
                                />
                            }

                            { content === 'listing' && 
                                <Listing 
                                    json={json}
                                    index={index}
                                    personStyle={uiColors}
                                    setAndGotoIndex={setAndGotoIndex}
                                    setAndGotoRandomIndex={setAndGotoRandomIndex}
                                />
                            }

                            { content === 'images' && 
                                <Fragment>
                                    { person !== null && mode === 'single' &&
                                        <Person 
                                            item={person}
                                            playing={playing}
                                            updateMode={updateMode}
                                            personStyle={uiColors}
                                        />
                                    }

                                    { json !== null && mode === 'grid' &&
                                        <Persons
                                            json={json}
                                            index={index}
                                            playing={playing}
                                            personStyle={uiColors}
                                            setAndGotoIndex={setAndGotoIndex}
                                            updateMode={updateMode}
                                        />
                                    }

                                    <Actions 
                                        index={index}
                                        mode={mode}
                                        playing={playing}
                                        personStyle={uiColors}
                                        togglePlaying={togglePlaying}
                                        updateMode={updateMode}
                                        setAndGotoRandomIndex={setAndGotoRandomIndex}
                                    />
                                </Fragment>
                            }

                            { content === 'cities' && 
                                <Cities 
                                    json={json}
                                    index={index}
                                    personStyle={uiColors}
                                    setAndGotoIndex={setAndGotoIndex}
                                    setAndGotoRandomIndex={setAndGotoRandomIndex}
                                />
                            }

                            { content === 'tv' && 
                                <Fragment>
                                    { person !== null && mode === 'single' &&
                                        <Person 
                                            item={person}
                                            playing={playing}
                                            updateMode={updateMode}
                                            personStyle={uiColors}
                                        />
                                    }

                                    { json !== null && mode === 'grid' &&
                                        <Persons
                                            json={json}
                                            index={index}
                                            playing={playing}
                                            personStyle={uiColors}
                                            setAndGotoIndex={setAndGotoIndex}
                                            updateMode={updateMode}
                                        />
                                    }

                                </Fragment>
                            }

                        </div>

                    </Fragment>
                }

                { json.length === 0 && 

                    <Fragment>
                        <div className="left">

                            <Info 
                                index={index}
                                personStyle={uiColors}
                            />

                            <Footer 
                                personStyle={uiColors}
                            />

                        </div>

                        <div className="right">
                            <div style={{ display: 'flex', flexWrap: 'wrap', height: '100%', alignContent: 'center', justifyContent: 'center', fontSize: '130px' }}>
                                ...
                            </div>
                        </div>

                    </Fragment>
                }

            </div>

            <Cursor 
                personStyle={uiColors}
            />

        </div>
    );
}

export default Main;
