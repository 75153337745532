import { Fragment, useEffect, useState } from 'react';
import { cdnUrl, cdnParams } from '../Helpers/Config';


function Person(props) {

    const [filename, setFilename] = useState('');
    const [filenameCurrent, setFilenameCurrent] = useState('');


    useEffect(() => {
        setFilename(filenameCurrent);
        setFilenameCurrent(props.item.file);

        setTimeout(() => {
            setFilename(null);
        }, 750);
    // eslint-disable-next-line
    },[props.item.file])

    useEffect(() => {
        if( props.playing === true ){
            let elemPerson = document.querySelector('.person');
            if( elemPerson !== null && elemPerson !== undefined ){
                elemPerson.classList.remove('fx');
                setTimeout(() => {
                    elemPerson.classList.add('fx');
                },50);
            }

            let elemProgress = document.querySelector('[data-item-progress]');
            if( elemProgress !== null && elemProgress !== undefined ){
                elemProgress.classList.remove('fx');
                setTimeout(() => {
                    elemProgress.classList.add('fx');
                },50);
            }
        }
        
    }, [props.item.FileName, props.playing]);

    return (

        <Fragment>

            <div 
                className="person"
                onClick={() => props.updateMode()}
            >

                <div
                    className="person__image"
                    style={{ backgroundImage: `url(${cdnUrl}${filenameCurrent}${cdnParams}&width=1200)` }}
                />

                {props.playing === true && 
                    <div 
                        data-item-progress
                        className="person__progress"
                        style={{ backgroundColor: props.personStyle.backgroundColor}}
                    ></div>
                }

            </div>

            <div 
                className={`person-old ${(filename !== null)?'person-old--active':''}`}
                style={{ backgroundImage: `url(${cdnUrl}${filename}${cdnParams}&width=1200)` }}
            ></div>
        </Fragment>

    );
}

export default Person;
