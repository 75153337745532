import { useEffect, useState, useRef, Fragment } from 'react';
import { baseUrl } from '../Helpers/Config';
import { IoVolumeHighSharp, IoVolumeMuteSharp, IoSyncSharp, IoSyncCircleOutline, IoNavigateSharp } from "react-icons/io5";



function Reels(props) {

    const videoRef = useRef(null);
    const loaderRef = useRef(null);

    const [reelIndex, setReelIndex] = useState(null);
    const [reel, setReel] = useState(null);
    const [muted, setMuted] = useState(false);
    const [progress, setProgress] = useState(0);


    useEffect(() => {
        if( props.jsonReels.length > 0 ){
            setReelIndex(0);    
        }
    // eslint-disable-next-line
    }, [props.jsonReels]);

    useEffect(() => {
        document.querySelector('.reels').classList.add('ended');

        let newReel = props.jsonReels[reelIndex];
        if( newReel !== undefined ){
            setReel(newReel);
            props.setReel(newReel);
            rotateRefresh();
        }
    // eslint-disable-next-line
    }, [reelIndex]);

    useEffect(() => {
        if( videoRef.current !== null ){

            setProgress(0);
            loaderRef.current.classList.add('active');
            
            videoRef.current.onloadstart = (e) => {
                // 
            }

            videoRef.current.onloadeddata = (e) => {
                // 
            }

            videoRef.current.onplay = (e) => {
                loaderRef.current.classList.remove('active');
            }

            videoRef.current.ontimeupdate = (e) => {
                const videoProgress = getPercentage(e.target);
                const videoContainer = document.querySelector('.reels');
                setProgress( videoProgress );
                if( videoProgress > 1 && videoContainer !== null ){
                    videoContainer.classList.remove('ended');
                }
            };

            videoRef.current.onended = (e) => {
                // document.querySelector('.reels').classList.add('ended');
                onReelEnd(e);
            };
        }
    // eslint-disable-next-line
    }, [reel]);

    

    const onReelEnd = (e) => {
        const newReelIndex = ( reelIndex < props.jsonReels.length-1 ) 
            ? reelIndex + 1 
            : 0;
        
        setReelIndex(newReelIndex);
    }

    const toggleMute = () => {
        setMuted(!muted);
    }

    const shuffleIndex = () => {
        document.querySelector('.reels').classList.add('ended');
        const minimum = 0;
        const maximum = props.jsonReels.length-1;
        const randomIdx = Math.floor(Math.random() * maximum) + minimum;
        if( randomIdx === reelIndex ){
            shuffleIndex();
            return false;
        }
        setReelIndex(randomIdx);
    }

    const rotateRefresh = () => {
        let elemReload = document.querySelector('[data-cta-reload]');
        if( elemReload !== null && elemReload !== undefined ){
            elemReload.classList.add('fx');
            setTimeout(() => {
                elemReload.classList.remove('fx');
            },1000);
        }
    }

    const getPercentage = (video) => {
        let totalLength = video.duration;   
        let percentageCompleted = (video.currentTime / totalLength) * 100;
        return percentageCompleted;
    }


    return (
        <div 
            className="reels"
        >

            <div 
                className="reels__loader"
                ref={loaderRef}
            >
                <IoSyncCircleOutline 
                    style={{ 
                        fill: props.personStyle.color,
                        stroke: props.personStyle.color
                    }}
                />
            </div>

            <button 
                className={`reels__shuffle`}
                onClick={() => shuffleIndex()}
                style={{ 
                    background: props.personStyle.backgroundColor,
                    borderColor: props.personStyle.color 
                }} 
            >
                <IoSyncSharp 
                    data-cta-reload
                    style={{ 
                        fill: props.personStyle.color,
                        stroke: props.personStyle.color
                    }}
                /> 

            </button>

            <button 
                className={`reels__mute`}
                onClick={() => toggleMute()}
                style={{ 
                    background: props.personStyle.backgroundColor,
                    borderColor: props.personStyle.color 
                }} 
            >
                { muted === false && 
                    <IoVolumeHighSharp 
                        style={{ 
                            fill: props.personStyle.color,
                            stroke: props.personStyle.color
                        }}
                    /> 
                }

                { muted === true && 
                    <IoVolumeMuteSharp 
                        style={{ 
                            fill: props.personStyle.color,
                            stroke: props.personStyle.color
                        }}
                    /> 
                }

            </button>

            { reel !== null && reel !== undefined && 
                <Fragment>

                    <div className={`reels__item`}>
                        <video
                            style={{ 
                                background: props.personStyle.backgroundColor,
                            }}
                            type="video/mp4" 
                            ref={videoRef}
                            src={`${baseUrl}${reel.file}`} 
                            alt={reel.title} 
                            autoPlay
                            playsInline
                            preload="auto"
                            muted={muted}
                        />
                    </div>

                    <div 
                        className="reels__info"
                        style={{ 
                            color: props.personStyle.color,
                            background: props.personStyle.backgroundColor
                        }}
                    >

                        { reel.title !== '' && 
                            <h2>
                                { reel.lat !== '' && reel.lng !== '' &&
                                    <a
                                        href={`https://www.google.com/maps/@${reel.city},${reel.lat},${reel.lng},16z;`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <IoNavigateSharp 
                                            style={{ 
                                                fill: props.personStyle.color,
                                            }}
                                        />
                                    </a>
                                }
                                {reel.title}
                            </h2>
                        }
                            
                        { reel.description !== '' && 
                            <h3>{reel.description}</h3>
                        }

                    </div>

                </Fragment>
            }

            <div 
                className="reels__progress" 
                style={{ 
                    width: `${progress}%`,
                    background: props.personStyle.backgroundColor
                }}
            />

        </div>
    );
}

export default Reels;
